/* global venue, venues, newscount */
import moment from "moment-timezone";
$().ready(function(){

	if ($("form.contact-form").length > 0){
		$("form.contact-form").validate();
	}

	$("#drop-button").click(function(){
		$(this).toggleClass("active");
		$(".enews #hidden").toggleClass("active");
	});
	loadFeatured();
	$(".infield").inFieldLabels();
	$("#menu-btn").click(function() {
		var obj = $(".header-bar-bottom");
		var obj2 = $(".header-bar-bottom .contentwrap");
		var height = obj2.height();
		height = height + 32;
		if(obj.height() > 0){
			obj.css("height", 0);
		} else {
			obj.css("height", height);
		}        	
	});
	AddFilter();
	eventssidebar();
	$(".controls .viewby").click(function(e) {
		e.stopPropagation();
		if ($(".controls .viewby .hover").is(":visible")) {
			$(".controls .viewby .hover").hide();
		} else {
			$(".controls .viewby .hover").show();
		}
	});		
	$(".controls .viewby .hover").on("click",function(e){
		e.stopPropagation();
	});
	$("body").on("click",function(evt) {
		const flyoutElement = $(".controls .viewby .hover").get(0);
		let targetElement = evt.target; // clicked element
		do {
			if (targetElement == flyoutElement || 
			(targetElement.classList && targetElement.classList.contains("ui-autocomplete"))) {
				// This is a click inside. Do nothing, just return.
				return;
			}
			// Go up the DOM
			targetElement = (targetElement) ? targetElement.parentNode: null;
		} while (targetElement);
		$(".controls .viewby .hover").hide();
	});
	$("#feedback-btn").click(function() {
		$("body").append("<div class='dialogfade'></div>");
		$("#feedback-form").show();
		document.location.hash="feedback-form";
		$(".dialogfade").click(function() {            
			$("#feedback-form").hide();
			$(this).remove();
		});
	});
	$("#feedback-close").click(function() {
		$("#feedback-form").hide();
		$(".dialogfade").remove();
	});
	$(".a-page #content table").wrap("<div class='table-overflow-container'></div>");
	eventGallery();
});

function slideImages(imageIndex, gallery) {
	imageIndex++;
	setImageClasses(imageIndex, gallery);
	if(imageIndex == gallery.length) imageIndex = 0;
}

function setImageClasses(imageIndex, gallery) {
	gallery.eq((imageIndex - 1) % gallery.length).addClass("left").removeClass("on");
	gallery.eq((imageIndex) % gallery.length).addClass("on").removeClass("right");
	gallery.eq((imageIndex + 1) % gallery.length).addClass("right").removeClass("left");
}

function swapImages() {
	var curImg = $(".imagerotate div.on");
	var newImg = $(".imagerotate div:not(.on)");
	curImg.addClass("left").removeClass("on");
	newImg.addClass("on").removeClass("right");
	setTimeout(function(){curImg.addClass("right").removeClass("left");}, 2000);
}

var rotateInt;
export function eventGallery(){
	var gallery = $(".imagerotate div");
	if (gallery.length == 0) {
		$(".imagerotate").hide();
	} else if (gallery.length == 1) {
		gallery.css({left:"0", right:"0", "opacity":"1"});
	} else if (gallery.length == 2) {
		gallery.eq(0).addClass("on");
		gallery.eq(1).addClass("right");
		
		if(rotateInt) {
			clearInterval(rotateInt);
		}
		rotateInt = setInterval(function(){swapImages();}, 5000);
	} else {
		var imageIndex = 0;		
		setImageClasses(imageIndex, gallery);		
		if(rotateInt) {
			clearInterval(rotateInt);
		}
		rotateInt = setInterval(function(){slideImages(imageIndex, gallery);},5000);
	}
}

export function eventssidebar () {
	$(".expando").click(function(e) {
		e.stopPropagation();
		var target = $(this).next(".expand");
		if (target.is(":visible")) {
			target.hide();
			$(this).removeClass("open");
		} else {
			target.show();
			$(this).addClass("open");
		}
	});
}
$(window).on("load",function(){
	loadNews();
});

export function AddFilter(){
	var x;
	for(x=0;x<7;++x) {
		$("#upcomingmonthfilter").append("<p><a href=\"#\" class=\"filter\" data-filter=\"dates\" data-value=\""+moment().add(x, "M").format("Y-M")+"\" style=\"color: #FFFFFF !important\">"+moment().add(x, "M").format("MMMM")+"</a></p>");
	}
}

	
/******** feature slider functions ********/
var featuredevents=[];
var bigblockonleft = false;
var sliderTimer;
var sliderDelay = 5000; 
var slidertransition = 500;
export function loadFeatured() {	
	if ($("#bannerslider").length > 0) {
		$.ajax({
			url:"/content/event/",
			data: {format:"json", featured:"yes", all:"true"},
			success:loadFeaturedSuccess,
			dataType: "json"});
	} else {
		loadUpcoming();
	}
}
export function loadFeaturedSuccess(data) {
	featuredevents = data.content_items;
	$("#bannerslider .loading").remove();

	//Build first two blocks.
	$("#bannerslider").append(buildBlock(new nextfetcher(),"top"));
	$("#bannerslider").append(buildBlock(new nextfetcher(),"bottom"));


	$("#bannernext").click(bannerNext);
	$("#bannerprev").click(bannerPrev);
	sliderTimer =setTimeout(bannerNext,sliderDelay);
	$("#bannerslider").hover(function(){
		clearTimeout(sliderTimer);
	}, function() {
		sliderTimer =setTimeout(bannerNext,sliderDelay);
	});
	loadUpcoming();
}
export function nextfetcher(){
	this.get = function() { //get next item from queue
		return featuredevents.shift();
	};
	this.put = function(item) {//put item on end of queue it's been scrolled past.
		featuredevents.push(item);
	};
	this.bigblock = function() {//put item on end of queue it's been scrolled past.
		bigblockonleft = !bigblockonleft;
		return bigblockonleft;
	};
}
export function prevfetcher(){
	this.get = function() { //get next item from queue
		return featuredevents.pop();
	};
	this.put = function(item) {//put item on end of queue it's been scrolled past.
		featuredevents.unshift(item);
	};
	this.bigblock = function() {//put item on end of queue it's been scrolled past.
		bigblockonleft = !bigblockonleft;
		return !bigblockonleft;
	};
}
export function buildBlock(fetcher, classes) {
	var block = $("<div class='sliderblock'></div>");
	if (classes) block.addClass(classes);
	block.data("items",[]);

	function additem(cssClass,width,height) {
		var item = fetcher.get();
		if (item != null) {
			block.append(createEventBlock(item,cssClass,width,height));
			block.data("items").push(item);
			return block;
		} else {
			//Generate item to place.
			switch(Math.floor(Math.random()*3)) {
			case 0:
				cssClass +=" tamworth-regional-entertainment-and-conference-centre";
				break;
			case 1:
				cssClass += " capitol-theatre-tamworth";
				break;
			case 2:
				cssClass += " town-hall-tamworth";
				break;
			}
			block.append("<div class='item placeholder "+ cssClass +"'></div>");
		}
	}
	var mode;
	if (fetcher.bigblock()) {
		mode = 0;
	} else {
		mode = Math.floor(Math.random()*2)+1; // 3 set layouts randonly chosen - 0=full block, 1= landscape, 2 = portrait.
	}

	switch(mode) {
	case 0:
		additem("full",490,490);
		break;
	case 1:
		additem("small top left",240,240);
		additem("small top right",240,240);
		additem("landscape bottom",490,490);
		break;
	case 2:
		additem("small top left",240,240);
		additem("small bottom left",240,240);
		additem("portrait right",490,490);
		break;
	}
	return block;

}
var month=new Array();
month[0]="Jan";
month[1]="Feb";
month[2]="Mar";
month[3]="Apr";
month[4]="May";
month[5]="Jun";
month[6]="Jul";
month[7]="Aug";
month[8]="Sep";
month[9]="Oct";
month[10]="Nov";
month[11]="Dec";

export function formatDate(date,includeyear) {
	return date.getDate() + " " +month[date.getMonth()] + (includeyear ? " " +date.getFullYear() :"");
}

export function createEventBlock(item,cssClass) {
	var itemLink =$("<a href='" + item.canonical_url + "'></a>");
	//TODO: calculate category CSS from category list?
	//var categoryCssClass="whoknows!"; //  item.category.toLowerCase().replace(/ /g,'-')
	var category_names = [];
	var statusCategory = null;
	var venueCategory = null;
	var badgingCategory = null;
	var img = null;
	var datestring = null;
	var start = null;
	var finish = null;
	for (var i=0; i < item.categories.length; i++) {
		var category = item.categories[i];
		switch (category.taxonomy_type) {
		case "venues":
			venueCategory = category.name.toLowerCase().replace(/ /g,"-");
			break;
		case "status":
			statusCategory = category.name.toLowerCase().replace(/ /g,"-");
			break;
		case "featured":
			break;
		case "badging":
			badgingCategory = category.name.toLowerCase().replace(/ /g,"-");
			break;
		default:
			category_names.push(category.taxonomy_type.toLowerCase().replace(/ /g,"-")+"-"+category.name.toLowerCase().replace(/ /g,"-"));
			break;
		}
	}

	img = null;
	if (item.images.length == 1) {
		img = item.images[0].image_url;
	} else if (item.images.length > 1) {
		var upcoming = item.images.filter(img => img.identifier =="upcomingimg");
		if (upcoming.length > 0 )
			img = upcoming[0].image_url;
	}
	var categoryCssClass = category_names.join(" ");
	var itemDiv = $("<div class='item "+ cssClass +" " + categoryCssClass +"'></div>");
	var itemContent = $("<div class='itemcontent'></div>");
	itemDiv.append(itemContent);


	//itemContent.append("<img src='/assets/gallery/"+item.image+"' width='"+width+"' height='"+height+"' alt='' class='tileimage'/>");
	itemDiv.css("background-image", "url('" + img + "')");

	var footerwrap = $("<div class='footerwrap'></div>");
	var footer = $("<div class='footer'></div>");
	footerwrap.append(footer);
	footer.append("<div class='bg'></div>");
	var content = "";
	if(item.custom_json && item.custom_json.shortname) {
		content = $("<div class='content'>"+item.custom_json.shortname+"</div>");
	} else {
		content = $("<div class='content'>"+item.title+"</div>");
	}
	if (item.start && item.start != "") {
		if (item.schedules.length >0) {
			for (i = 0; i<item.schedules.length; i++) {
				if (moment.tz(item.schedules[i].final_finish, "utc") > moment()) {
					start = item.schedules[i].start;
					break;
				}
			}
			if (start) {
				start = moment.tz(start, "utc").local().format("DD MMM YYYY");
				finish = item.schedules[item.schedules.length-1].final_start;
				finish = moment.tz(finish, "utc").local().format("DD MMM YYYY");
				if (start){
					datestring = start;
					if (start != finish) {
						datestring += " - "+finish;
					}
				}
				content.append("<p class='date'>"+datestring+"</p>");
			}
		}
	} else {
		itemDiv.addClass("nodate");
	}
	//TODO: where is location stored? How is it retreived
	if (venueCategory && venueCategory != "") {
		itemDiv.addClass(venueCategory);
	} else {
		itemDiv.addClass("noloc");
	}
	if (venueCategory != null) {content.append("<p class='location'>"+venueCategory.toUpperCase().replace(/-/g," "));}
	footer.append(content);
	itemContent.append(footerwrap);
	if (statusCategory)
		itemContent.append("<div class='status "+ statusCategory+"' title='" + statusCategory + "'></div>");
	if (badgingCategory)
		itemDiv.append("<div class='badging "+ badgingCategory+"' title='" + badgingCategory + "'><span>" + badgingCategory + "</span></div>");
	itemLink.append(itemDiv);
	return itemLink;
}
/* this should match the query defined in website.css:490*/
var mobileQuery = "all and (max-width:900px)";
var TransSupport = supportsTransitions();
export function bannerNext() {
	if (matchMedia(mobileQuery).matches) {
		bannerNextMobile();
	} else {
		bannerNextDesktop();
	}
}
export function bannerNextDesktop() {
	if ($("#bannerslider").is(":animated")) return;
	clearTimeout(sliderTimer);
	var fetcher = new nextfetcher();
	$("#bannerslider").append(buildBlock(fetcher));
	$("#bannerslider").animate({marginLeft:"-500px"},slidertransition,"linear",function(){
		var block = $("#bannerslider .sliderblock:first");
		var item;
		while ((item = block.data("items").pop())){
			//put item back in queue.
			fetcher.put(item);
		}
		block.remove();
		$("#bannerslider").css("margin-left",0);
		sliderTimer =setTimeout(bannerNext,sliderDelay);
		//Set CSS for mobile view Just In Case (TM) user decides to shrink screen.
		block = $("#bannerslider .sliderblock:first").addClass("top").removeClass("bottom");
		block = $("#bannerslider .sliderblock:last").addClass("bottom").removeClass("top");
	});
}
export function bannerNextMobile() {
	if ($("#bannerslider").data("animating")) return;
	$("#bannerslider").data("animating",true);
	clearTimeout(sliderTimer);
	var fetcher = new nextfetcher();
	$("#bannerslider").append(buildBlock(fetcher,"from-right bottom"));
	var slides = $("#bannerslider .sliderblock");

	function cleanup() {
		var item;
		while ((item = slides.eq(0).data("items").pop())){
			//put item back in queue.
			fetcher.put(item);
		}
		slides.eq(0).remove();
		sliderTimer =setTimeout(bannerNext,sliderDelay);
		$("#bannerslider").data("animating",false);
	}
	//check for transitions to trigger finish, or use timer.
	if (TransSupport) {
		slides.eq(0).bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",cleanup);
	} else {
		setTimeout(cleanup,1000);
	}

	slides.eq(0).addClass("to-left");
	slides.eq(1).addClass("top").removeClass("bottom");
	// eslint-disable-next-line no-unused-vars
	var width = slides.eq(2).width(); //trigger initial paint.
	slides.eq(2).removeClass("from-right");
}
export function bannerPrev() {
	if (matchMedia(mobileQuery).matches) {
		bannerPrevMobile();
	} else {
		bannerPrevDesktop();
	}
}
export function bannerPrevDesktop() {
	if ($("#bannerslider").is(":animated")) return;
	clearTimeout(sliderTimer);
	$("#bannerslider").css("margin-left","-500px");
	var fetcher = new prevfetcher();
	$("#bannerslider").prepend(buildBlock(fetcher));
	$("#bannerslider").animate({marginLeft:"0"},slidertransition,"linear",function(){
		var block = $("#bannerslider .sliderblock:last");
		var item;
		while ((item = block.data("items").pop())){
			//put item back in queue.
			fetcher.put(item);
		}
		block.remove();
		sliderTimer =setTimeout(bannerPrev,sliderDelay);
		//Set CSS for mobile view Just In Case (TM) user decides to shrink screen.
		block = $("#bannerslider .sliderblock:first").addClass("top").removeClass("bottom");
		block = $("#bannerslider .sliderblock:last").addClass("bottom").removeClass("top");
	});
}
export function bannerPrevMobile() {
	if ($("#bannerslider").data("animating")) return;
	$("#bannerslider").data("animating",true);
	clearTimeout(sliderTimer);
	var fetcher = new prevfetcher();
	$("#bannerslider").prepend(buildBlock(fetcher,"to-left top"));
	var slides = $("#bannerslider .sliderblock");

	function cleanup() {
		var item;
		while ((item = slides.eq(2).data("items").pop())){
			//put item back in queue.
			fetcher.put(item);
		}
		slides.eq(2).remove();
		sliderTimer =setTimeout(bannerPrev,sliderDelay);
		$("#bannerslider").data("animating",false);
	}
	//check for transitions to trigger finish, or use timer.
	if (TransSupport) {
		slides.eq(2).bind("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd",cleanup);
	} else {
		setTimeout(cleanup,1000);
	}

	// eslint-disable-next-line no-unused-vars
	var width = slides.eq(0).width(); //trigger initial paint.
	slides.eq(0).removeClass("to-left");
	slides.eq(1).addClass("bottom").removeClass("top");
	slides.eq(2).addClass("from-right bottom");
}

/******** Upcoming events ********/

var upcomingData = {page:1, count:8, venues:[], dates:[], status:[] };
//Process query string for pre-filter
var params = window.location.search.substring(1).split("&");
for (var i=0; i < params.length;i++){
	var pair = params[i].split("=");
	if (decodeURIComponent(pair[0])) {
		var filter = decodeURIComponent(pair[0]);
		var value = decodeURIComponent(pair[1]);
		// exclude form result queries
		if (filter.indexOf("form_") == -1){
			$(".filter[data-filter='" + filter + "'][data-value='" + value + "']").addClass("active");
			upcomingData[filter] = [value];
		}
	}
}
var venueInitialLoad = true;
export function loadUpcoming() {
	if (venueInitialLoad) {
		if (venue != "Entertainment Venues")
			upcomingData.venues.push(venue);
		$(".controls .viewby .hover .filter[data-value='"+venue+"' i]").addClass("active");
		venueInitialLoad=false;
	}
	$("#upcomingevents").append("<div id='loadingscreen'><img src='/app/img/loader.gif' alt=''></div>");
	if ($("#upcomingevents").length > 0) {
		$.ajax({
			url:"/content/event/",
			data: upcomingData,
			traditional: true,
			success:loadUpcomingSuccess,
			dataType: "json"});
	}
}
export function loadUpcomingSuccess(data)  {
	$("#upcomingevents #loadingscreen").hide();
	if (data.content_items_total > 0) {
		if(upcomingData.page == 1) {
			$("#upcomingevents").html("");
		}
		for (i =0; i < data.content_items.length; i++) {
			var currentEvent = data.content_items[i];
			$("#upcomingevents").append(createEventBlock(currentEvent,(i % 4 == 3) ? "small right" : "small",240,240));
		}

		if (data.content_items.length < 8) {
			$(".upcoming .showmore").hide();
		}else {
			$(".upcoming .showmore").show();
			upcomingData.page++;
		}
	} else {
		$("#upcomingevents").html("<h2 class=\"noitems\">Sorry, No events matching your search.</h2>");
		$("#upcomingevents").append("<h3>Please broaden your search.</h3>");
		if (upcomingData.venue != "entertainmentvenues"){
			$("#upcomingevents").append("<a id=\"searchall\" class=\"button\">Search All Venues</a>");
		}
		$(".upcoming .showmore").hide();
	}
}
var upcomingSearchTimer;
export function initUpcomingSearch() {
	clearTimeout(upcomingSearchTimer);
	$("#upcomingevents").append("<div id='loadingscreen'><img src='/app/img/loader.gif' alt=''></div>");
	upcomingSearchTimer = setTimeout(function () {
		upcomingData.page =1;
		upcomingData.query =$("#upcomingsearch").val();
		if(upcomingData.query == "") {
			delete upcomingData["query"];
		}
		loadUpcoming();
	},500);
}

$(document).ready(function() {
	if (typeof venues == "undefined") return;
	$(".upcoming .showmore").click(function(e) {
		e.stopPropagation();
		loadUpcoming();
	});
	$(".upcoming .controls .viewby .hover,.upcoming .controls").on("click",".filter",function(e) {
		e.stopPropagation();
		var filter = $(this).attr("data-filter");
		var value = $(this).attr("data-value");
		$("#upcomingevents").html("<div id='loadingscreen'><img src='/app/img/loader.gif' alt=''></div>");
		upcomingData.page =1;
		if (value == "") {
			upcomingData[filter] = [];
			$(".controls .viewby .hover .filter[data-filter='"+filter+"']").removeClass("active");
		} else {
			if ($(this).hasClass("active")) {
				var index = $.inArray(value,upcomingData[filter]);
				if (index >-1){
					upcomingData[filter].splice(index,1);
				}
				$(this).removeClass("active");
				
			} else {
				if ($(this).attr("data-mode") == "radio") {
					upcomingData[filter] =[value];
					$(".upcoming .filter[data-filter='"+filter+"']").removeClass("active");
				}else{
					upcomingData[filter].push(value);
				}
				$(this).addClass("active");					
			}
		}
		loadUpcoming();
		//$(".controls .viewby .hover").hide();
		return false;
	});
	$("#upcomingsearch").keyup(initUpcomingSearch);
	$(".upcoming").on("click", "#searchall", function(e) {
		e.stopPropagation();
		$("#upcomingevents").html("<div id='loadingscreen'><img src='/app/img/loader.gif' alt=''></div>");
		$("#upcomingevents #loadingscreen").show();
		upcomingData.page =1;
		upcomingData["venues"] = [];
		$(".upcoming .controls .filter[data-filter=\"venues\"]").removeClass("active");
		loadUpcoming();
		return false;
	});
	$(".upcoming .searchwrap .clear").click(function() {
		$("#upcomingevents").html("<div id='loadingscreen'><img src='/app/img/loader.gif' alt=''></div>");
		$("#upcomingsearch").val("");
		initUpcomingSearch();
	});    
	var addvenue = function(value){
		$(".viewby .generated").each(function(index,el){
			var $el = $(el);
			var $filter = $el.find(".filter");
			var filter = $filter.attr("data-filter");
			var value = $filter.attr("data-value");
			index = $.inArray(value,upcomingData[filter]);
			if (index >-1){
				upcomingData[filter].splice(index,1);
			}
			$el.remove();
		});
		var newFilter = $("<p class=\"generated\"><a href=\"#\" class=\"filter\" data-filter=\"venues\" data-value=\""+value+"\">"+value+"</a></p>");
		$(".venue_spacer").before(newFilter);
		setTimeout(function(){
			$("#venue_other").val("");
			$("#venue_other").blur("");
		},100);
		newFilter.find("a").trigger("click");
	};
	$("#venue_other").autocomplete({
		source:venues,
		select: function(event, ui){
			addvenue(ui.item.value);
		}
	});
});
/******** News functions ********/

/******** News feed functions ********/
var newsitem = 0;
export function loadNewsFeed(){
	$.ajax({
		url:"/content/news/",
		data: {format:"json", count:1, page:newsitem},
		success:loadNewsFeedSuccess,
		dataType: "json"});
}
export function newsup() {
	newsitem++;
	newsitem = (newsitem > newscount) ? 1:newsitem;
	loadNewsFeed();
}
export function newsdown() {
	newsitem--;
	newsitem = (newsitem < 1) ? (newscount):newsitem;
	loadNewsFeed();
}

export function loadNewsFeedSuccess(data) {
	var news_item = data.content_items[0];
	$(".newsfeed div.newsitem").html("<a href=\""+news_item.url+"\"><h2>"+news_item.title+"</h2>"+news_item.summary+"...</a>");
}


/******** News layout functions ********/
var NewsData = {page:1, month:[]};
export function loadNews(){
	if ($(".news .newsitems").length >0) {
		$.ajax({
			url:"/content/news/",
			data:NewsData,
			success:loadNewsSuccess,
			dataType: "json"});
	}
}
export function loadNewsSuccess(data){
	if (data.content_items_total > 0) {
		for (i = 0; i < data.content_items.length;i++) {
			var newsItem = data.content_items[i];
			$(".news .newsitems").append(
				"<div class=\"newsitem clearfix masonry-item" + ((i%4) ==3 ? " right" : "") + "\">"+
				"<a href=\""+newsItem["canonical_url"] + "\">"+								
					"<img src=\""+ newsItem["graphic_url"]+"\" width=\"240\" height=\"240\" />"+
				"<h1>"+ newsItem["title"] +"</h1>"+
				"<p class=\"date\">" + newsItem["start"] + "</p>"+
				"<div class=\"teaser\">" + newsItem["teaser"]+ "</div>"+
				"</a>"+
			"</div>"
			);					
		}
		if (data.newscount > data.content_items.length + (NewsData.page*8)) {
			$(".news .showmore").show();
			NewsData.page++;
		}else {
			$(".news .showmore").hide();
		}
	} else {
		$(".news .newsitems").html("<h2 class=\"noitems\">No news matching your search. Please broaden your search</h2>");
		$(".news .showmore").hide();
	}
	$(".news .newsitems .loading").remove();
	$(".newsitems.masonry").imagesLoaded(function(){
		$(".newsitems.masonry").masonry("reload");
	});
	
}
var newsSearchTimer;
export function initNewsSearch() {
	clearTimeout(newsSearchTimer);
	$(".news .newsitems").html("<img src=\"app/img/loader.gif\" width=\"48\" height=\"48\" alt=\"loading\" class=\"loading masonry-item\"/>");
	newsSearchTimer = setTimeout(function () {
		NewsData.page =0;
        
		NewsData.filter =$("#newssearch").val();
		loadNews();
	},500);
}

$(document).ready(function() {	
	$(".newsup").click(newsup);
	$(".newsdown").click(newsdown);	
	$(".controls .viewby .hover .newsfilter").click(function(e) {
		e.stopPropagation();
		var filter = $(this).attr("data-filter");
		var value = $(this).attr("data-value");
		$(".news .newsitems").html("<img src=\"app/img/loader.gif\" width=\"48\" height=\"48\" alt=\"loading\" class=\"loading masonry-item\"/>");
		NewsData.page =0;
		if (value == "") {
			NewsData[filter] = [];
			$(".controls .viewby .hover .newsfilter[data-filter='"+filter+"']").removeClass("active");
		} else {
			if ($(this).hasClass("active")) {
				var index = $.inArray(value,NewsData[filter]);
				if (index >-1){
					NewsData[filter].splice(index,1);
				}
				$(this).removeClass("active");
				
			} else {
				NewsData[filter].push(value);
				$(this).addClass("active");
			}
		}
		loadNews();
		return false;
	});
	$("#newssearch").keyup(initNewsSearch);
	$(".news .searchwrap .clear").click(function() {
		$("#newssearch").val("");
		initNewsSearch();
	});
});

/* https://github.com/paulirish/matchMedia.js/ */
/*! matchMedia() polyfill - Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas, David Knight. Dual MIT/BSD license */

window.matchMedia || (window.matchMedia = function() {
	"use strict";

	// For browsers that support matchMedium api such as IE 9 and webkit
	var styleMedia = (window.styleMedia || window.media);

	// For those that don't support matchMedium
	if (!styleMedia) {
		var style       = document.createElement("style"),
			script      = document.getElementsByTagName("script")[0],
			info        = null;

		style.type  = "text/css";
		style.id    = "matchmediajs-test";

		if (script && script.parentNode) {
			script.parentNode.insertBefore(style, script);
		}
		// 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
		info = ("getComputedStyle" in window) && window.getComputedStyle(style, null) || style.currentStyle;

		styleMedia = {
			matchMedium: function(media) {
				var text = "@media " + media + "{ #matchmediajs-test { width: 1px; } }";

				// 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
				if (style.styleSheet) {
					style.styleSheet.cssText = text;
				} else {
					style.textContent = text;
				}

				// Test if media query is true or false
				return info.width === "1px";
			}
		};
	}

	return function(media) {
		return {
			matches: styleMedia.matchMedium(media || "all"),
			media: media || "all"
		};
	};
}());

/* http://stackoverflow.com/questions/7264899/detect-css-transitions-using-javascript-and-without-modernizr */
export function supportsTransitions() {
	var b = document.body || document.documentElement,
		s = b.style,
		p = "transition";

	if (typeof s[p] == "string") { return true; }

	// Tests for vendor specific prop
	var v = ["Moz", "webkit", "Webkit", "Khtml", "O", "ms"];
	p = p.charAt(0).toUpperCase() + p.substr(1);

	for (var i=0; i<v.length; i++) {
		if (typeof s[v[i] + p] == "string") { return true; }
	}

	return false;
}